import React from "react";

import Layout from "../components/layout/layout.component";
import Footer from "../sections/footer/footer.component";
import SubpageHero from "../components/subpageHero/subpageHero.component";
import OurCompanyDesc from "../sections/subpageSections/ourCompanySubpage/ourCompanyDesc.component";
import Contact from "../components/contact/contact.component";
import SEO from "../components/seo.component";
import { LANG } from "../lang/lang";

const OurComapnyPage = () => {
  return (
    <Layout>
      <SEO title={LANG.PL.ourCompanySubpage.seoTitle} />
      <SubpageHero
        title={LANG.PL.ourCompanySubpage.title}
        subTitle={LANG.PL.ourCompanySubpage.subTitle}
      >
        <OurCompanyDesc />
        <Contact />
        <Footer />
      </SubpageHero>
    </Layout>
  );
};

export default OurComapnyPage;
