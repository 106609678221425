import React from 'react';
import parse from 'html-react-parser';
import {
  StyledCompanyContent,
  StyledCompanySection,
  StyledCompanyText,
  StyledCompanyTitle,
  StyledCompanyWrapper,
  StyledOverviewDivider,
  StyledOverviewDividerIcon,
} from './ourCompanyDesc.style';
import DividerIcon from '../../../images/smallVoltspotIcon.png';
import { StyledCenter } from '../../../utils/styledComponents/center.style';
import { LANG } from '../../../lang/lang';

const OurCompanyDesc = (): JSX.Element => {
  return (
    <StyledCompanyWrapper>
      <StyledCenter>
        <StyledCompanyContent>
          <StyledCompanyTitle>
            {LANG.PL.ourCompanySubpage.sectionTitle_1}
          </StyledCompanyTitle>
          <StyledCompanySection>
            <StyledCompanyText>
              {parse(LANG.PL.ourCompanySubpage.text_1)}
            </StyledCompanyText>
            <StyledCompanyText>
              {parse(LANG.PL.ourCompanySubpage.text_2)}
            </StyledCompanyText>
          </StyledCompanySection>
          <StyledCompanyTitle>
            {LANG.PL.ourCompanySubpage.sectionTitle_2}
          </StyledCompanyTitle>
          <StyledCompanySection>
            <StyledCompanyText>
              {parse(LANG.PL.ourCompanySubpage.text_3)}
            </StyledCompanyText>
            <StyledCompanyText>
              {parse(LANG.PL.ourCompanySubpage.text_4)}
            </StyledCompanyText>
          </StyledCompanySection>
          <StyledOverviewDivider>
            <StyledOverviewDividerIcon src={DividerIcon} />
          </StyledOverviewDivider>
        </StyledCompanyContent>
      </StyledCenter>
    </StyledCompanyWrapper>
  );
};

export default OurCompanyDesc;
