import styled from "styled-components";

export const StyledCompanyWrapper = styled.section`
  margin-bottom: 150px;
`;

export const StyledCompanyContent = styled.div`
  padding: 0 149px;
  height: 100%;

  @media only screen and (max-width: 768px) {
    padding: 0 50px;
  }
  @media only screen and (max-width: 425px) {
    padding: 0 20px;
  }
`;

export const StyledCompanyText = styled.p`
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: ${({ theme }) => theme.colors.black};
  margin: 30px 0;
  text-align: left;
  strong {
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  }
  @media only screen and (max-width: 425px) {
    font-size: ${({ theme }) => theme.fontSizes.xsmall};
  }
`;

export const StyledCompanySection = styled.div`
  background-color: ${({ theme }) => theme.colors.grey};
  padding: 60px;
  margin-bottom: 50px;
  box-shadow: 0 8px 24px 0 rgb(0 0 0 / 4%);
  margin-top: 10px;
`;

export const StyledCompanyTitle = styled.h3`
  color: ${({ theme }) => theme.colors.lighterBlack};
  font-weight: ${({ theme }) => theme.fontWeights.light};
  font-size: ${({ theme }) => theme.fontSizes.xxlarge};
  margin: 0;

  @media only screen and (max-width: 680px) {
    font-size: ${({ theme }) => theme.fontSizes.xlarge};
  }

  @media only screen and (max-width: 500px) {
    font-size: ${({ theme }) => theme.fontSizes.large};
  }
`;

export const StyledOverviewDivider = styled.div`
  margin: 219px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &::before,
  &::after {
    content: "";
    display: block;
    height: 5px;
    min-width: 200px;
    background: ${({ theme }) => theme.colors.silver};
  }
  &::before {
    margin-right: 70px;
  }

  &::after {
    margin-left: 70px;
  }
  @media only screen and (max-width: 425px) {
    &::before {
      margin-right: 20px;
    }

    &::after {
      margin-left: 20px;
    }
  }
`;

export const StyledOverviewDividerIcon = styled.img``;
